<template>
    <div class="root-wrap">
        <div class="body-wrap fx-fill">
            <div class="fill-box">
                <div class="movie_detail-bg">
                    <div class="movie_detail-bg--img" :style="`background-image: url(${movie.logo}_800x800);`"></div>
                    <div class="movie_detail-content fx-end">
                        <div class="detail_info-wrap fx-column fx-end">
                            <!-- linear-gradient(to top, RGBA(62, 74, 88, 1) 5%, rgba(255, 255, 255, 0) 50%),-->
                            <div :style="`background-image: url(${movie.logo}_180x180);`" class="detail_info-poster">
                            </div>
                            <div class="detail_info fx-row fx-center fx-between">
                                <div class="fx-middle fx-fill">
                                    <span class="detail_info-name">{{movie.name}}</span>
                                    <span>时长：{{movie.duration}}分钟</span>
                                    <span>上映日期：{{movie.playdate}}</span>
                                    <span>语言：{{movie.language | format}}</span>
                                    <span>类型：{{movie.type | format}}</span>
                                </div>
                                <span v-show="movie.rating && movie.rating > 0" class="fc-rate">{{movie.rating}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="movie_detail-item">
                    <van-cell title="影片简介" center :is-link="hasExpand" :border="false" @click="expand = !expand" title-style="font-weight: 900">
                        <template v-if="hasExpand" #right-icon>
                            <van-icon :name="expand ? 'arrow-up' : 'arrow-down'" style="margin-left: 5px;" size="10" :color="$config.style.cancelColor"></van-icon>
                        </template>
                        <div v-if="hasExpand" style="color: #3F4956;">展开</div>
                    </van-cell>
                    <div class="movie_detail-item--content">
                        <div :class="expandClass" class="movie_detail-item--introduce" ref="introduce">{{movie.content}}</div>
                    </div>
                </div>
                <div class="movie_detail-item">
                    <van-cell title="剧照" center :border="false" title-style="font-weight: 900"></van-cell>
                    <div class="movie_detail-item--content fx-row">
                        <div v-for="item,index in stills" :key="index" class="stills-image">
                            <van-image :src="item" width="122" height="73" :lazy-load="true"></van-image>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-wrap">
            <div class="movie_detail-action fx-center-middle">
                <van-button type="primary" block round @click="toCinema">立即购票</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { getMovieDetail } from '@api/movie-request'
export default {
    data() {
        return {
            hackReset: false,
            movieId: this.$route.query.movieId,
            movie: {},
            actorList: [],
            hasExpand: false,
            expand: false,
        }
    },
    computed: {
        introduceArrow() {
            return this.expand ? 'up' : 'down';
        },
        stills() {
            if (this.movie.trailers) {
                let stills = this.movie.trailers.split(',');
                return stills;
            }
            return [];
        },
        expandClass() {
            if (!this.hasExpand) return '';
            if (this.expand) return '';
            if (!this.expand) return 'van-multi-ellipsis--l3';
            return '';
        }
    },
    methods: {
        toCinema() {
            this.$goPage('cinema', {
                movieId: this.movieId,
                movieName: this.movie.name
            })
        },
        getMovieDetail() {
            return new Promise((resolve) => {
                getMovieDetail(this.movieId).then((res) => {
                    this.movie = res;
                    resolve();
                })
            })
        },
        getMovieActorImage() {
            return new Promise((resolve) => {
                this.$service.getMovieActorImage({ movieId: this.movieId, picture: '2' }).then((res) => {
                    this.actorList = res;
                    resolve();
                })
            })
        },
        initPage() {
            this.getMovieActorImage();
            this.getMovieDetail().then(() => {
                this.$nextTick(() => {
                    let height = this.$refs.introduce.getBoundingClientRect().height;
                    let lineHeight = 20;
                    this.$refs.introduce.style.lineHeight = `${lineHeight}px`;
                    let rows = Math.round(height / lineHeight);
                    if (rows > 3) {
                        this.hasExpand = true;
                    }
                })
            });
        }
    },
    mounted() {
        let test = "http://ss"
        console.log(test.replace('http', 'https'))
        this.initPage();
        this.hackReset = false;
        this.$nextTick(() => {
            this.hackReset = true;
        })
    },
    filters: {
        format(val) {
            return val ? val.replace(RegExp(',', 'g'), '/') : '';
        }
    }
}
</script>

<style lang="scss" scoped>
.body-wrap {
	position: relative;
	.movie_detail-bg {
		height: 250px;
		position: relative;
		color: #fff;
		.movie_detail-bg--img {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-size: cover;
			background-position: top;
			transition: background-image 0.5s ease-in;
			z-index: 0;
		}
		.movie_detail-content {
			height: 160px;
			width: 100%;
			position: absolute;
			left: 0;
			bottom: 0;
			.detail_info-wrap {
				padding: 8px 0;
				width: 100%;
				bottom: 0;
				background: rgba(62, 74, 88, 0.5);
				.detail_info-poster {
					width: 90px;
					height: 135px;
					position: absolute;
					left: 15px;
					bottom: 15px;
					background-size: cover;
					background-position: center;
					border-radius: 4px;
				}
				.detail_info {
					width: calc(100% - 90px - 15px);
					padding: 0 15px;
					> div {
						width: calc(100% - 80px);
						font-size: 12px;
						span {
							@include txt-ellipsis();
							font-size: 13px;
							padding: 2px 0;
							letter-spacing: 1px;
							&.detail_info-name {
								font-size: 18px;
								font-weight: 700;
							}
						}
					}
					> span {
						font-size: 18px;
						font-weight: 900;
					}
				}
			}
		}
	}
	.movie_detail-item {
		margin-bottom: 10px;
		.movie_detail-item--content {
			width: 100%;
			padding: 0px 15px;
			padding-bottom: 10px;
			background-color: #fff;
			line-height: 20px;
			overflow-x: auto;
			> span {
				display: inline-block;
				min-width: 25%;
			}
			.stills-image:not(:nth-last-child(1)) {
				margin-right: 12.5px;
				> div {
					border-radius: 4px;
					overflow: hidden;
				}
			}

			.movie_detail-item--introduce {
				line-height: 20px;
			}
		}
	}
}
.footer-wrap {
	.movie_detail-action {
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		background-color: #fff;
		padding: 13px 15px;
	}
}
</style>
